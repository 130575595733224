import React from "react"
import Layout from "../components/Layout/Layout"
import HelmetWrapper from "../components/HelmetWrapper/HelmetWrapper"
import "./portfolio.scss"
import { ProjectCard } from "../components/ProjectCard/ProjectCard"
import { graphql, useStaticQuery } from "gatsby"

const PortfolioPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      allProjectsJson {
        edges {
          node {
            title
            url
            description
          }
        }
      }
    }
  `)
  const projects = data.allProjectsJson.edges
  return (
    <>
      <HelmetWrapper title="Porfolio" />
      <Layout pathname={location.pathname}>
        <div className="portfolio-page-container">
          <div className="portfolio-page-header">
            <h1>My Portfolio</h1>
          </div>
          <div className="portfolio-page-subheader">
            <p>Presented here are some of my favourite projects</p>
          </div>
          <div className="portfolio-content">
            {projects.map(({ node: project, idx }) => {
              const title = project.title
              const description = project.description
              const url = project.url
              return (
                <ProjectCard
                  key={idx}
                  id={idx}
                  projectTitle={title}
                  imageUrl={title}
                  content={description}
                  projectUrl={url}
                />
              )
            })}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PortfolioPage
