import React from "react"
import { Card, CardBody } from "shards-react"
import "./ProjectCard.scss"
import CrowdStart from "../../../data/images/Campaign.png"
import meme from "../../../data/images/memeGenerator.png"
import projectPlanner from "../../../data/images/projectPlanner.png"
import taskManager from "../../../data/images/TaskManager.png"

const KEYWORDS = [
  "React,",
  "Redux,",
  "Firebase,",
  "cloud",
  "functions",
  "Hooks,",
  "Node.JS,",
  "Twitter",
  "Rest",
  "API,",
  "Google",
  "Natural",
  "Languages",
  "API,",
  "MongoDB",
  "Express.",
  "Next.JS,",
  "Semantic UI,",
  "Web3.JS,",
  "Truffle.JS",
  "Solidity",
  "Mocha.JS",
  "Redux",
  "ImgFlip",
  "API",
  "Nest.JS",
  "TypeScript",
  "TypeScript,",
  "Sass",
  "Thunk,",
  "Repository",
  "pattern",
  "backend",
]

export const ProjectCard = ({
  id,
  projectTitle,
  imageUrl,
  content,
  projectUrl,
}) => {
  function getImageFromImageUrl(url) {
    switch (url) {
      case "Project Planner": {
        return projectPlanner
      }
      case "CrowdStart": {
        return CrowdStart
      }
      case "Redux Meme Generator": {
        return meme
      }
      case "Task Manager": {
        return taskManager
      }
      default:
        return url
    }
  }

  function highligtKeyWords(content) {
    let words = content.split(" ")
    let highlightedWords = []

    words.map(word => {
      if (KEYWORDS.includes(word)) {
        highlightedWords.push(<b>{word}</b>)
        highlightedWords.push(" ")
      } else {
        highlightedWords.push(word)
        highlightedWords.push(" ")
      }

      return null
    })

    return highlightedWords
  }

  return (
    <Card className="project-card" key={id}>
      <CardBody className="project-card-body">
        <div className="project-card-image">
          <img
            src={getImageFromImageUrl(imageUrl)}
            alt={projectTitle}
            height={250}
            width={"100%"}
          />
        </div>
        <div className="project-card-header">
          <label>{projectTitle}</label>
        </div>
        <div className="project-card-description">
          <p className="constant">{highligtKeyWords(content)}</p>
        </div>
      </CardBody>
      <div className="spacer" />
      <div className="project-link">
        <a href={`${projectUrl}`} target="_blank" rel="noopener noreferrer">
          View this project.
        </a>
      </div>
    </Card>
  )
}
